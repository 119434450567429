import React, { useEffect, useState } from 'react'
import { Alert, Layout, Spin, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/cleanui/layout/TopBar'
import Menu from 'components/cleanui/layout/Menu'
import Footer from 'components/cleanui/layout/Footer'
import { AddPortfolioModal, AddPositionModal, AdjustBrokerageAccountModal } from 'components/modals'
import { BackendHelper } from 'utils/index'

const mapStateToProps = ({ settings, user }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
  currentPortfolioLoading: user.currentPortfolioLoading,
})

const MainLayout = ({
  children,
  isContentMaxWidth,
  isAppMaxWidth,
  isGrayBackground,
  isSquaredBorders,
  isCardShadow,
  isBorderless,
  isTopbarFixed,
  isGrayTopbar,
  currentPortfolioLoading,
}) => {
  const [bannerNotifications, setBannerNotifications] = useState([])
  const [bannerNotificationsLoading, setBannerNotificationsLoading] = useState(true)

  useEffect(() => {
    BackendHelper.get(`user/notifications`, { 'template.id': 'banner' }).then((notifications) => {
      setBannerNotifications(notifications)
      setBannerNotificationsLoading(false)
    })
  }, [currentPortfolioLoading])

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const { Title, Paragraph } = Typography

  return (
    <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
      {!bannerNotificationsLoading && bannerNotifications.length > 0 && (
        <Alert message={bannerNotifications[0].template.message} banner closable />
      )}
      <Layout
        className={classNames({
          cui__layout__contentMaxWidth: isContentMaxWidth,
          cui__layout__appMaxWidth: isAppMaxWidth,
          cui__layout__grayBackground: isGrayBackground,
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless,
        })}
      >
        <Menu />
        <Layout>
          <Layout.Header
            className={classNames('cui__layout__header', {
              cui__layout__fixedHeader: isTopbarFixed,
              cui__layout__headerGray: isGrayTopbar,
            })}
          >
            <TopBar />
          </Layout.Header>
          <AddPortfolioModal />
          <AddPositionModal />
          <AdjustBrokerageAccountModal />
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            {!currentPortfolioLoading && <div className="cui__utils__content">{children}</div>}
            {currentPortfolioLoading && (
              <div className="cui__utils__content">
                <div>
                  <div style={{ textAlign: 'center' }}>
                    <div className="ml-auto mr-auto">
                      <Spin indicator={antIcon} style={{ marginTop: 150 }} spinning="true" />
                    </div>
                    <Title style={{ marginTop: 50 }} level={4}>
                      Loading Portfolio
                    </Title>
                    <Paragraph>Just hold on a sec...</Paragraph>
                  </div>
                </div>
              </div>
            )}
          </Layout.Content>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
