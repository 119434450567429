import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'antd'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  theme: settings.theme,
})

const Footer = (theme) => {
  const [visible, setVisible] = useState(false)

  const hide = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const myPolicy = 76982377

  useEffect(() => {
    const s = document.createElement('script')
    const tag = document.getElementsByTagName('script')[0]

    s.src = 'https://cdn.iubenda.com/iubenda.js'

    tag.parentNode.insertBefore(s, tag)
  }, [])

  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        {theme === 'default' && (
          <img src="resources/images/logo-default.svg" className="mr-2" alt="SwingTrackr logo" />
        )}
        {theme === 'dark' && (
          <img src="resources/images/logo-dark.svg" className="mr-2" alt="SwingTrackr logo" />
        )}
        <br />
        <p className="mb-0"> Copyright © {new Date().getUTCFullYear()} SwingTrackr</p>
        <p>
          <Modal
            title="Disclaimer"
            visible={visible}
            onOk={hide}
            onCancel={hide}
            footer={[
              <Button key="submit" type="primary" onClick={hide}>
                Okay
              </Button>,
            ]}
          >
            <p>
              SwingTrackr is for informational purposes only as SwingTrackr is not registered as a
              securities broker-dealer or an investment adviser. No information herein is intended
              as securities brokerage, investment, tax, accounting or legal advice, as an offer or
              solicitation of an offer to sell or buy, or as an endorsement, recommendation or
              sponsorship of any company, security or fund.
            </p>
            <p>
              SwingTrackr cannot and does not assess, verify or guarantee the adequacy, accuracy or
              completeness of any information, the suitability or profitability of any particular
              investment, or the potential value of any investment or informational source. The
              reader bears responsibility for his/her own investment research and decisions, should
              seek the advice of a qualified securities professional before making any investment,
              and investigate and fully understand any and all risks before investing.
            </p>
            <p>
              SwingTrackr in no way warrants the solvency, financial condition, or investment
              advisability of any of the securities mentioned in communications or websites. In
              addition, SwingTrackr accepts no liability whatsoever for any direct or consequential
              loss arising from any use of this information. This information is not intended to be
              used as the sole basis of any investment decision, should it be construed as advice
              designed to meet the investment needs of any particular investor. Past performance is
              not necessarily indicative of future returns.
            </p>
          </Modal>
          <a role="button" onClick={setVisible} tabIndex="0" styling="link" onKeyDown={setVisible}>
            {' '}
            Disclaimer
          </a>{' '}
          |{' '}
          <a
            href={`https://www.iubenda.com/privacy-policy/${myPolicy}`}
            className="iubenda-nostyle no-brand iubenda-embed"
            title="Privacy Policy"
          >
            Privacy Policy
          </a>{' '}
          |{' '}
          <a
            href={`https://www.iubenda.com/api/privacy-policy/${myPolicy}/cookie-policy`}
            className="iubenda-nostyle no-brand iubenda-embed"
            title="Cookie Policy"
          >
            Cookie Policy
          </a>{' '}
          |{' '}
          <a
            href={`https://www.iubenda.com/api/terms-and-conditions/${myPolicy}`}
            className="iubenda-nostyle no-brand iubenda-embed"
            title="Terms and Conditions"
          >
            Terms and conditions
          </a>
        </p>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Footer)
