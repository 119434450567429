import firebase from 'firebase/app'
import { notification } from 'antd'
import { BackendHelper } from 'utils/index'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/analytics'
import 'firebase/performance'
import { getUnixTime } from 'date-fns'
import jwt from 'jsonwebtoken'

const PRICING_PLANS = ['free', 'bronze', 'gold', 'platinum']
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.performance()

export const firebaseAuth = firebase.auth()

export async function login(email, password) {
  return firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(() => true)
    .catch((error) => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function register(email, password, firstName, lastName) {
  const body = { email, password, firstName, lastName, pricingPlan: PRICING_PLANS[0] }
  let publicAuthToken

  if (!process.env.REACT_APP_LOCAL_BACKEND) {
    // TODO: Externalize this stuff into an Auth file or something
    // in the event that we have more public endpoints than just register
    const now = getUnixTime(new Date())
    const clientEmail = process.env.REACT_APP_PUBLIC_ENDPOINT_EMAIL
    const key = process.env.REACT_APP_PUBLIC_ENDPOINT_KEY
    const aud = process.env.REACT_APP_PUBLIC_ENDPOINT_AUD

    const payload = {
      iat: now,
      exp: now + 3600,
      iss: clientEmail,
      sub: clientEmail,
      email: clientEmail,
      aud,
    }

    publicAuthToken = jwt.sign(payload, key, { algorithm: 'RS256' })
  }

  return BackendHelper.register('users', { body }, publicAuthToken)
    .then(() => firebaseAuth.signInWithEmailAndPassword(email, password))
    .catch((error) => {
      notification.error({
        message: 'Registration Error',
        description: `There was an error while registering: ${error.message}`,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false

  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }

      const unsubscribe = auth.onAuthStateChanged((user) => {
        userLoaded = true
        unsubscribe()
        resolve(user)
      }, reject)
    })
  }

  return getCurrentUser(firebaseAuth)
}

export async function logout() {
  return firebaseAuth.signOut().then(() => true)
}

export async function forgot(email) {
  firebaseAuth
    .sendPasswordResetEmail(email)
    .then(() => {
      notification.success({
        message: 'Password reset successful',
        description: `Reset link sent to ${email}`,
      })

      return true
    })
    .catch((error) => {
      notification.error({
        message: 'Something went wrong',
        description: error.message,
      })
    })
}
