import { stringify } from 'query-string'
import { firebaseAuth } from '../services/firebase'

function getAuthenticationHeader(token) {
  if (process.env.REACT_APP_LOCAL_BACKEND) {
    return { 'x-endpoint-api-userinfo': token.split('.')[1] }
  }

  return { Authorization: `Bearer ${token}` }
}

const API_PREFIX = process.env.REACT_APP_BACKEND_ENDPOINT || ''

async function base(
  endpoint,
  { camelize = true, query = {}, body, method = 'get', publicToken } = {},
) {
  /* eslint no-unneeded-ternary: "error" */
  const token = publicToken ? publicToken : await firebaseAuth?.currentUser?.getIdToken()
  const rawBody = body ? JSON.stringify(body) : undefined
  const headers = { 'Content-Type': 'application/json' }
  const qs = camelize ? { camelize, ...query } : query

  if (token) Object.assign(headers, getAuthenticationHeader(token))

  const response = await fetch(`${API_PREFIX}/${endpoint}?${stringify(qs)}`, {
    method,
    body: rawBody,
    headers,
  })

  if (!response.ok) {
    const errorMessage = await response.text()

    throw new Error(errorMessage)
  }

  // CHECKME: This logic can potentially be removed if all core-api
  // requests return a body
  try {
    const responseBody = await response.json()

    return responseBody
  } catch (e) {
    return {}
  }
}

export function get(endpoint, { camelize, query } = {}) {
  return base(endpoint, { camelize, query })
}

export function patch(endpoint, { camelize, body } = {}) {
  return base(endpoint, { body, camelize, method: 'PATCH' })
}

export function del(endpoint, { query } = {}) {
  return base(endpoint, { query, method: 'DELETE' })
}

export function put(endpoint, { camelize, body } = {}) {
  return base(endpoint, { body, camelize, method: 'PUT' })
}

export function post(endpoint, { camelize, body } = {}) {
  return base(endpoint, { body, camelize, method: 'POST' })
}

export function register(endpoint, { body } = {}, publicToken) {
  return base(endpoint, { body, method: 'POST', publicToken })
}
