const Format = new Intl.NumberFormat('en-EN', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

const FormatPennyStock = new Intl.NumberFormat('en-EN', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
})

export default function formatCurrency(number, referencePrice) {
  if (referencePrice === undefined) return Format.format(number)

  return referencePrice >= 1 || referencePrice <= -1 || Number(referencePrice) === 0
    ? Format.format(number)
    : FormatPennyStock.format(number)
}
