import React from 'react'
import { Button, Dropdown, Menu, Skeleton, Space } from 'antd'
import { connect } from 'react-redux'
import styles from './style.module.scss'

const mapStateToProps = ({ settings, user }) => ({
  addPortfolioModalOpen: settings.addPortfolioModalOpen,
  portfolioList: user.portfolioList,
  currentPortfolio: user.currentPortfolio,
  currentPortfolioLoading: user.currentPortfolioLoading,
})

const PortfolioSelector = ({
  dispatch,
  portfolioList,
  currentPortfolio,
  currentPortfolioLoading,
}) => {
  const addPortfolio = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'addPortfolioModalOpen',
        value: true,
      },
    })
  }

  const clickHandler = (item) => {
    const portfolioID = item.key

    if (portfolioID === 'addPortfolio') {
      addPortfolio()
    } else {
      dispatch({
        type: 'user/LOAD_CURRENT_PORTFOLIO',
        payload: { portfolioID },
      })
    }
  }

  const portfolioMenuItems = portfolioList.map((portfolio) => (
    <Menu.Item key={portfolio.id}>{portfolio.name}</Menu.Item>
  ))

  const size = 'small'
  const active = true
  const avatarShape = 'circle'

  const menu = (
    <Menu selectable={false} onClick={clickHandler}>
      <Menu.ItemGroup>{portfolioMenuItems}</Menu.ItemGroup>
      <Menu.Divider />
      <Menu.Item key="addPortfolio">
        <i className="fe fe-plus mr-2" /> Add a new Portfolio
      </Menu.Item>
    </Menu>
  )

  if (portfolioList.length > 0) {
    return (
      <div>
        {!currentPortfolioLoading && (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
            <div className={styles.dropdown}>
              <i className={`${styles.icon} fe fe-book`} />
              <span className="d-xl-inline">{currentPortfolio.name}</span>
            </div>
          </Dropdown>
        )}
        {currentPortfolioLoading && (
          <Space>
            <Skeleton.Avatar
              style={{ display: 'block' }}
              active={active}
              size={size}
              shape={avatarShape}
            />
            <Skeleton.Input style={{ display: 'block', width: 200 }} active={active} size={size} />
          </Space>
        )}
      </div>
    )
  }

  return (
    <div className={styles.firstPortfolio}>
      <Button
        className={styles.firstButton}
        key="portfolioSelector"
        type="primary"
        onClick={addPortfolio}
      >
        <i className={`${styles.firstIcon} fe fe-book`} />
        Add a portfolio
        <i className={`${styles.firstIcon} fe fe-plus`} />
      </Button>
    </div>
  )
}

export default connect(mapStateToProps)(PortfolioSelector)
