import React, { useCallback, useState } from 'react'
import { symbols } from '@swingtrackr/stock-exchange-symbols'
import { Select } from 'antd'

const { Option } = Select

const FLATTENED_SYMBOLS = symbols.reduce((acc, curr) => {
  curr.exchanges.forEach((e) => {
    acc.push({ symbol: curr.symbol, name: curr.name, exchange: e })
  })

  return acc
}, [])

const TickerAutoCompleteList = ({ exclude = [], value = {}, onChange }) => {
  const [searchResults, setSearchResults] = useState([])

  const preFilteredList = FLATTENED_SYMBOLS.filter(
    (t) => !exclude.some((e) => e.symbol === t.symbol && e.exchange === t.symbol),
  )

  const onSearch = useCallback(
    (searchValue) => {
      const results = []

      // Direct search for exact match
      const directIndex = preFilteredList.findIndex(
        (i) =>
          i.name.toUpperCase() === searchValue.toUpperCase() ||
          (searchValue.length <= 5 && i.symbol.toUpperCase() === searchValue.toUpperCase()),
      )

      if (directIndex !== -1) {
        results.push(preFilteredList[directIndex])
      }

      // Fuzzy search for submatches
      // return top 5 results for match text in symbol or name
      for (let i = 0; i < preFilteredList.length; i += 1) {
        if (i !== directIndex) {
          const currentItem = preFilteredList[i]

          if (
            currentItem.name.substr(0, searchValue.length).toUpperCase() ===
              searchValue.toUpperCase() ||
            (searchValue.length <= 5 &&
              currentItem.symbol.substr(0, searchValue.length).toUpperCase() ===
                searchValue.toUpperCase())
          ) {
            results.push(currentItem)

            if (results.length === 5) break
          }
        }
      }

      setSearchResults(results)
    },
    [preFilteredList, setSearchResults],
  )

  return (
    <Select
      value={Object.keys(value).length > 0 ? JSON.stringify(value) : undefined}
      style={{
        minWidth: 250,
      }}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      showSearch
      onSearch={onSearch}
      onChange={(v) => onChange?.(JSON.parse(v))}
      placeholder="Select a ticker"
      notFoundContent={null}
    >
      {searchResults.map((ticker) => (
        <Option key={`${ticker.symbol}-${ticker.exchange}`} value={JSON.stringify(ticker)}>
          <div style={{ display: 'inline-block', width: 60, marginRight: 10 }}>{ticker.symbol}</div>
          <span>{ticker.name}</span>
          <span style={{ float: 'right' }}>{ticker.exchange}</span>
        </Option>
      ))}
    </Select>
  )
}

export default TickerAutoCompleteList
