import React, { useState } from 'react'
import { Form, InputNumber, Modal, Radio, Space, Typography } from 'antd'
// import { Link } from 'react-router-dom'
import { BackendHelper, formatCurrency } from 'utils/index'
import Big from 'big.js'
import { connect } from 'react-redux'
import './styles.scss'
import './override.css'

const ADJUSTMENT_TYPE = {
  deposit: 'Deposit',
  withdraw: 'Withdrawal',
  dividend: 'Dividend',
  manual: 'Manual',
}

const mapStateToProps = ({ settings, user }) => ({
  adjustBrokerageAccountModalOpen: settings.adjustBrokerageAccountModalOpen,
  userId: user.id,
  portfolioList: user.portfolioList,
  brokerageAccount: user.currentPortfolio.brokerageAccount,
  portfolioId: user.currentPortfolio.id,
})
const { Title } = Typography

const AdjustBrokerageAccountModal = (props) => {
  const { dispatch, adjustBrokerageAccountModalOpen, brokerageAccount, portfolioId } = props
  const [loading, setLoading] = useState(false)
  const [adjustmentType, setAdjustmentType] = useState(null)
  const [adjustmentAmount, setAdjustmentAmount] = useState(0)

  function clearModal() {
    form.resetFields()
    setAdjustmentType(null)
    setAdjustmentAmount(0)
  }

  const closeAdjustBrokerageAccountModal = () => {
    clearModal()
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'adjustBrokerageAccountModalOpen',
        value: false,
      },
    })
  }

  const onAdjustBrokerageAccount = async (values) => {
    setLoading(true)
    const body = {
      type: values.type,
      amount: values.amount,
    }

    const endpoint = values.type === 'manual' ? 'adjustbalance' : values.type

    await BackendHelper.post(`portfolios/${portfolioId}/${endpoint}`, { body })

    dispatch({
      type: 'user/QUIET_LOAD_PORTFOLIO',
      payload: {
        portfolioID: portfolioId,
      },
    })
    setLoading(false)
    closeAdjustBrokerageAccountModal()
  }

  const updatedBalance = (type) => {
    let amount

    if (type === 'deposit' || type === 'dividend') {
      amount = formatCurrency(
        Big(brokerageAccount.balance)
          .plus(adjustmentAmount || 0)
          .toFixed(2),
      )
    } else if (type === 'withdraw') {
      amount = formatCurrency(
        Big(brokerageAccount.balance)
          .minus(adjustmentAmount || 0)
          .toFixed(2),
      )
    } else if (type === 'manual') {
      amount = formatCurrency(adjustmentAmount)
    }

    return <Title level={3}>Updated Balance: {amount}</Title>
  }

  const [form] = Form.useForm()

  const formBody = (
    <div>
      <Title level={3}>
        Current Balance: {brokerageAccount ? formatCurrency(brokerageAccount.balance) : '-'}
      </Title>
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: 'public' }}
      >
        <Form.Item
          name="type"
          label="Adjustment Type"
          hasFeedback
          rules={[{ required: true, message: 'Please set the adjustment type' }]}
        >
          <Radio.Group
            buttonStyle="solid"
            value={adjustmentType}
            onChange={(e) => {
              setAdjustmentType(e.target.value)
            }}
          >
            <Space>
              <Radio.Button value="deposit">Deposit</Radio.Button>
              <Radio.Button value="withdraw">Withdrawal</Radio.Button>
              <Radio.Button value="dividend">Dividend</Radio.Button>
              <Radio.Button value="manual">Manual</Radio.Button>
            </Space>
          </Radio.Group>
        </Form.Item>
        {adjustmentType && (
          <div>
            <Form.Item
              name="amount"
              hasFeedback
              label={`${ADJUSTMENT_TYPE[adjustmentType]} Amount`}
              initialValue={0}
              rules={[{ type: 'number', min: 0 }]}
            >
              <InputNumber
                style={{ maxWidth: '150px' }}
                value={adjustmentAmount}
                onChange={(value) => setAdjustmentAmount(value)}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(?:\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </div>
        )}
      </Form>
      {adjustmentType && brokerageAccount && <div>{updatedBalance(adjustmentType)}</div>}
    </div>
  )

  return (
    <Modal
      title="Adjust Brokerage Account"
      className="base-modal"
      centered
      destroyOnClose
      confirmLoading={loading}
      visible={adjustBrokerageAccountModalOpen}
      onCancel={closeAdjustBrokerageAccountModal}
      onOk={() => {
        form.validateFields().then((values) => {
          onAdjustBrokerageAccount(values)
        })
      }}
      style={{ borderStyle: 'solid', borderColor: 'white' }}
    >
      <div style={{ minHeight: '340px' }}>{formBody}</div>
    </Modal>
  )
}

export default connect(mapStateToProps)(AdjustBrokerageAccountModal)
